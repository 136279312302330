import Vue from 'vue';
import VueRouter from 'vue-router';
import VueLodash from 'vue-lodash';

import moment from 'moment';
import Vuelidate from 'vuelidate';

import lodash from 'lodash';

import App from './App.vue';
import routes from './router';
import auth from './auth';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.use(VueRouter);
const VueMoment = require('vue-moment');

// Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

const router = new VueRouter({
  mode: 'history', // add fallback route to prod server
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

auth.interceptorsSetup(store, router);

Vue.prototype.moment = moment;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');

Vue.use(Vuelidate);
Vue.use(VueMoment);
// Vue.use(SocialSharing);
Vue.use(VueLodash, {
  name: 'custom',
  lodash,
});

// Vue.use(VueMatomo, {
//   // Configure your matomo server and site by providing
//   host: 'https://matomo.hratuga.de/matomo/',
//   siteId: 1,

//   // Changes the default .js and .php endpoint's filename
//   // Default: 'matomo'
//   trackerFileName: 'matomo',

//   // Overrides the autogenerated tracker endpoint entirely
//   // Default: undefined
//   // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

//   // Overrides the autogenerated tracker script path entirely
//   // Default: undefined
//   // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

//   // Enables automatically registering pageviews on the router
//   router,

//   // Enables link tracking on regular links. Note that this won't
//   // work for routing links (ie. internal Vue router links)
//   // Default: true
//   enableLinkTracking: true,

//   // Require consent before sending tracking information to matomo
//   // Default: false
//   requireConsent: false,

//   // Whether to track the initial page view
//   // Default: true
//   trackInitialView: true,

//   // Run Matomo without cookies
//   // Default: false
//   disableCookies: true,

//   // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
//   // Default: false
//   enableHeartBeatTimer: false,

//   // Set the heartbeat timer interval
//   // Default: 15
//   heartBeatTimerInterval: 15,

//   // Whether or not to log debug information
//   // Default: false
//   debug: false,

//   // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
//   // Default: undefined
//   userId: undefined,

//   // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
//   // Default: undefined, example '*.example.com'
//   cookieDomain: undefined,

//   // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
//   // Default: undefined, example: '*.example.com'
//   domains: undefined,

//   // A list of pre-initialization actions that run before matomo is loaded
//   // Default: []
//   // Example: [
//   //   ['API_method_name', parameter_list],
//   //   ['setCustomVariable','1','VisitorType','Member'],
//   //   ['appendToTrackingUrl', 'new_visit=1'],
//   //   etc.
//   // ]
//   preInitActions: [],
// });

// Vue.use(VueKeyCloak, {
//   config: {
//     realm: 'Mosaik',
//     url: 'https://auth.anmelde-tool.de/auth',
//     clientId: 'inspirator-localhost',
//   },
//   onReady: (keycloak) => {
//     console.log(`I wonder what Keycloak returns: ${keycloak}`);
//   },
// });
