<template>
  <div v-html="require(`./inspi.svg`)"></div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'icon-name',
});
</script>
