<template>
  <v-banner>
    <v-icon
      app
      slot="icon"
      color="warning"
      size="36">
      mdi-alert-circle-outline
    </v-icon>
      Keine Verbindung zum Server. Bitte später erneut probieren.
  </v-banner>
</template>

<script>
export default {
  methods: {
  },
};
</script>
